<template>
  <div>
    供应商  {{showData.providerName}}  将由
    <span style="color:#FF8200">
      {{showData.adjustAdvice=='1'?'一次升级到一般':showData.adjustAdvice=='2'?'一次升级到合格':showData.adjustAdvice=='3'?'一般升级到合格'
      :showData.adjustAdvice=='4'?'一般降级到一次':showData.adjustAdvice=='5'?'合格降级到一般':showData.adjustAdvice=='6'?'合格降级到淘汰'
      :showData.adjustAdvice=='7'?'一次降级到淘汰':'一般降级到淘汰'}}
    </span>
    <span v-if="showData.adjustAdvice=='2'||showData.adjustAdvice=='3'">
    ，请选择部件负责人
    <el-select filterable v-model="bjfzrdt.data" :required="true" placeholder="请选择部件负责人">
      <el-option
          v-for="item in bjfzrsplist"
          :key="item"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
    </span>
  </div>
</template>

<script>

export default {
  name: 'LifecycleDialog',
  props: ['showData', 'bjfzrsplist', 'bjfzr'],
  data () {
    return {
      bjfzrdt: this.bjfzr
    }
  }
}
</script>
<style scoped>
</style>
